nav.nav{
    position: absolute;
    bottom: 0;
    margin: 0 $space*3;
    
    ul{
        list-style: none;
        
        > li{
            position: relative;
            float: left;
            
            a {
                display: block;
                position: relative;
                color: $nav-font-color;
                font-size: $nav-font-size;
                font-weight: 400;
                padding: $space/2 $space;
                line-height: 19px;
                text-transform: uppercase;
                background: $nav-background;
                margin-right: 5px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                
                &:hover, &:focus, &:active, &.selected{
                    color: $nav-font-color-active;
                    background: $nav-background-active;
                    text-decoration: none;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }
            &.selected{
                a{
                    color: $nav-font-color-active;
                    background: $nav-background-active;
                }
            }
            &.has-dropdown{
                &:hover{
                    .dropdown-menu {
                        display: block;
                    }
                }
                .dropdown-menu {
                    background: $background-color;
                    box-shadow: 5px 5px rgba(102,102,102,.1);
                    left: 0;
                    min-width: 175px;
                    position: absolute;
                    z-index: 1000;
                    display: none;
                    float: left;
                    list-style: none;
                    text-shadow: none;
                    padding: 0;
                    margin: 0;
                    border-radius: 0;
                    border: 0;
                    
                    li{
                        a{
                            background: $nav-background-active;
                            white-space: nowrap;
                            
                            &:hover{
                                color: $nav-font-color;
                            }
                        }
                    }
                }
            }
            /*
            &:hover, &.open, &.selected{
                color: $nav-font-color-active;
                background: $background-color;
                text-decoration: none;
                > a {
                    color: $nav-font-color-active;
                    background: $nav-inactive, 30;
                }
            }*/
        }
        /*
        > a {
            &:hover{
                color: #ffffff;
                background: lighten($nav-inactive, 30);
            }
        }*/
/*
        .menu-dropdown{

            &:hover{
                background: $background-color;
                
                .dropdown-menu {
                    display: block;
                }
            }
            .dropdown-menu {
                box-shadow: 5px 5px rgba(102,102,102,.1);
                left: 0;
                min-width: 175px;
                position: absolute;
                z-index: 1000;
                display: none;
                float: left;
                list-style: none;
                text-shadow: none;
                padding: 0;
                background-color: lighten($nav-inactive, 30);
                margin: 0;
                border-radius: 0;
                border: 0;
                > li{
                    background: lighten($nav-inactive, 30);
                    a{
                            color: $nav-font-color;
                        &:hover{
                            color: $nav-font-color;
                        }
                    }
                }
            }
        }*/
    }
}

