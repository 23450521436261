.portlet {
    padding: 12px 20px 15px;
    margin-bottom: 20px;
    background-color: $portlet-background-color;
    .portlet-title {
        padding: 0;
        min-height: 40px;
        border-bottom: 1px solid #eee;
        padding: 0;
        margin-bottom: 10px;
        
        h2 {
            font-family: $font;
            color: $portlet-title-color;
            font-size: 25px;
            font-weight: 300;
            float: left;
            text-transform: uppercase;
        }
        .actions{
            float: right;
        }
    }
    .portlet-body {
        &.form{
            margin-right: -$space;
        }
        .row{
            margin-bottom: $space/2;
        }
    }
}
.portlet-meta{
    margin: -10px $space $space $space;
    overflow: hidden;
}