/*** colors *******************************************************************/

$color-red:                             #cc0000;
$color-grey1:                           #e5e5e5;
$color-grey2:                           #cccccc;
$color-grey3:                           #999999;
$color-grey4:                           #666666;
$color-grey5:                           #333333;
$color-black:                           #000000;
$color-white:                           #ffffff;

$color-redbg:                           #cc3737;
$color-orangebg:                        #ff8534;
$color-yellowbg:                        #fcd435;
$color-lightgreenbg:                    #5bca36;
$color-greenbg:                         #2a962a;

/*** main *********************************************************************/
$background-color:                      #efefef;
$space:                                 20px;
$highlight-color1:                      $color-red;
$highlight-color2:                      #333333;

/*** typo *********************************************************************/
$font:                                  'Open Sans', sans-serif;
$font-color:                            $color-black;
$font-size:                             16px;
$line-height:                           1.5;
$link-color:                            $color-black;


/*** header *******************************************************************/
$header-height:                         80px;
$header-background:                     #fff;

/*** navigation ***************************************************************/
$nav-background:                        #ccc;
$nav-background-active:                 $background-color;
$nav-font-size:                         18px;
$nav-font-color:                        #333;
$nav-font-color-active:                 #333;

/*** portlets *****************************************************************/
$portlet-background-color:              #ffffff;
$portlet-title-color:                   #666666;

/*** messages *****************************************************************/
$message-success-background:            #b9daa2;
$message-success-border:                #409b00;
$message-error-background:              #ffb9b9;
$message-error-border:                  #d10000;
$message-warning-background:            #faeaa9;
$message-warning-border:                #f3cc31;
$message-color:                         #333333;



/*** forms ********************************************************************//*
$input-padding-top:                     15px;
$input-margin-bottom:                   $space;

$input-font-color:                      $highlight-color2;
$input-font-size:                       $font-size;
$input-font-weight:                     700;
$input-font-weight-wysiwyg:             400;
$input-border-color:                    #666666;
$input-focus-border-color:              $highlight-color1;
$input-label-font-color:                #999999;
$input-focus-label-font-color:          darken(#999999, 30);
$input-help-font-color:                 $highlight-color1;
$input-button-color:                    #ffffff;
$input-button-background:               $highlight-color1;
$input-button2-color:                   #ffffff;
$input-button2-background:              $highlight-color2;

$imagecontainer-width:                  180px;
$imagecontainer-padding:                5px;
$imagecontainer-border:                 1px;
$imagecontainer-border-color:           #7f8893;
$imagecontainer-color:                  #7f8893;
$imagecontainer-inner-width:            ($imagecontainer-width - 2 * $imagecontainer-padding - 2 *  $imagecontainer-border);
$imagecontainer-padding:                5px;
$imageupload-controls-color:             #ffffff;

$documentcontainer-border:                 1px;
$documentcontainer-border-color:           #7f8893;
$documentcontainer-color:                  #7f8893;
$documentcontainer-padding:                15px;
$documentupload-controls-color:            #ffffff;



$input-label-font-size:                 14px;
$input-label-opacity:                   1;


$img-circle-bg:                         #eb6f78;
*/