/******************************************************************************/
/*** LOAD dvGUI DEFAULT-SETTINGS **********************************************/
/******************************************************************************/
@import "../../_gui/dvf_gui_assets/scss/default-settings";

/******************************************************************************/
/*** OVERWRITE dvGUI DEFAULT-SETTINGS *****************************************/
/******************************************************************************/

$highlight-color1:                      $color-grey4;


/******************************************************************************/
/*** BOOTSTRAP dvGUI SCSS *****************************************************/
/******************************************************************************/
@import "../../_gui/dvf_gui_assets/scss/init";



/******************************************************************************/
/*** SPECIFIC STYLES **********************************************************/
/******************************************************************************/

@import "netzhdk";

body{
    header{
        .page-header-top {
            .page-logo {
                .logo {
                    height:70%;
                    height:calc(100% - 40px);
                    margin-top: 20px;
                }
            }
        }
    }
}
.dvgui-draggable{
    cursor: pointer;
}
.dvgui-droppable{
    min-height: 200px;
    background: $color-grey1;
    border: $color-grey3 solid 1px;
    
    &.ui-state-active{
        border: $highlight-color1;
       
    }
    &.ui-state-hover{
        border: $highlight-color1 dotted 1px;
        background: lighten($highlight-color1, 50)
    }
}