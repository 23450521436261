.btn,
a.btn,a:active.btn,a:visited.btn,a:focus.btn,{
    padding: 5px 10px;
    background: $highlight-color1;
    color: $color-white;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    text-transform: uppercase;
    
    &:hover{
        background: lighten($highlight-color1, 15%);
    }
    &.grey{
        background: $color-grey2;
    }
}

.action-col, .actions, .action-icons{

    a, a:link, a:active, a:visited{
        background: $highlight-color1;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-block;
        color: $color-white;
        font-size: 29px;
        text-align: center;
        line-height: 30px;
        vertical-align: middle;
        cursor: pointer;
        
        i{
            font-size: 17px;
            line-height: 30px;
            display: block;
        }
        &.mt{
            margin-top: 15px;
        }
        &:hover{
            color: darken($color-white,20);
        }
    }
}
a, a:link, a:active, a:visited{
    &.action-icon{
        background: $highlight-color1;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: inline-block;
        color: $color-white;
        font-size: 29px;
        text-align: center;
        line-height: 30px;
        vertical-align: middle;
        cursor: pointer;
        
        i{
            font-size: 17px;
            line-height: 30px;
            display: block;
        }        
    }
}
.pointer{
    cursor: pointer;
}