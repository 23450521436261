.note{
    margin: $space*2;
    border: 0;
    border-left: 5px solid #ccc;
    padding: $space;
    
    &.note-error{
        background: $message-error-background;
        border-left: 5px solid $message-error-border;
    }
    &.note-success{
        background: $message-success-background;
        border-left: 5px solid $message-success-border;
    }
    &.note-warning{
        background: $message-warning-background;
        border-left: 5px solid $message-warning-border;
    }
    &.inline{
        margin-left: 0;
        margin-right: 0;
    }
    &.top{
        margin-top: 0;
    }
    &.note-logout{
        margin: $space;
        display: none;
        
        #logout_countdown{
            color: $color-red;
        }
        #logout_countdown_raw{
            display: none;
        }
    }
} 
.portlet{
    .note{
        margin: $space 0;
    }
}
@media(max-width:1024px){
    .note{
        margin: 0 0 40px 0;
    }
}