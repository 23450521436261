table.dataTable{
    
    thead{
        
        th, td{
            text-align: left;
            padding: 8px 10px;
            font-size: 14px;
            border-bottom: solid 2px $color-grey2;
            font-weight: 700;
            color: $color-grey3
        }
        .sorting{
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='20' viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23CCCCCC' fill-rule='evenodd' opacity='1' d='M 1.6364142,11.501244 H 13.273234 c 1.046337,0 1.569505,1.266358 0.831203,2.004662 l -5.8184106,5.81841 c -0.4596056,0.459606 -1.2027973,0.459606 -1.6575141,0 L 0.80521285,13.505906 C 0.06691005,12.767602 0.59007785,11.501244 1.6364142,11.501244 Z M 14.104437,6.3673518 8.2860264,0.54894112 c -0.4596056,-0.45960548 -1.2027973,-0.45960548 -1.6575141,0 L 0.80521285,6.3673518 C 0.06691005,7.1056545 0.59007785,8.3720142 1.6364142,8.3720142 H 13.273234 c 1.046337,0 1.569505,-1.2663597 0.831203,-2.0046624 z'/%3E%3C/svg%3E");
        }
        .sorting_asc{
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='20' viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23999999' fill-rule='evenodd' opacity='1' d='M 14.104437,6.3673518 8.2860264,0.54894112 c -0.4596056,-0.45960548 -1.2027973,-0.45960548 -1.6575141,0 L 0.80521285,6.3673518 C 0.06691005,7.1056545 0.59007785,8.3720142 1.6364142,8.3720142 H 13.273234 c 1.046337,0 1.569505,-1.2663597 0.831203,-2.0046624 z'/%3E%3C/svg%3E");
        }
        .sorting_desc{
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='20' viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23999999' fill-rule='evenodd' opacity='1' d='M 1.6364142,11.501244 H 13.273234 c 1.046337,0 1.569505,1.266358 0.831203,2.004662 l -5.8184106,5.81841 c -0.4596056,0.459606 -1.2027973,0.459606 -1.6575141,0 l -5.82329945,-5.81841 c -0.7383028,-0.738304 -0.215135,-2.004662 0.83120135,-2.004662 z'/%3E%3C/svg%3E");
        }
    }
    tbody{
        
        td, th {
            padding: 5px 10px;
            vertical-align: top;
            
            &.txt-r{
                text-align: right;
            }
            &.action-col{
                /*padding: 0 10px;*/
                white-space: nowrap;
            }
            .nobr{
                white-space: nowrap;
            }
            small{
                font-size: 10px;
                
                &.overflow_height{
                    display: inline-block;
                    max-height: 45px;
                    overflow: hidden;
                }
            }
        }
    }
    
    tfoot{
        
        th, td{
            text-align: left;
            padding: 8px 10px;
            font-size: 14px;
            border-top: solid 2px $color-grey2;
        }
    }
    &.no-footer{
        border-bottom: solid 2px $color-grey2;
    }
    .order-param{
        display: none;
    }
}
.dataTables_wrapper {
    
    .dataTables_paginate {
        
        .paginate_button {
            background: $color-grey1;
            padding: 5px 10px;
            border: 0;
            color: $color-black;
            
            &:hover {
                background: darken($color-grey1,10%);
                border: 0;
                color: $color-black !important;
            }
            
            &.current, &.current:hover {
                background: $color-grey2;
                border: 0;
            }
        }
    }
    .dataTables_filter{
        font-size: 14px;
        
        input {
            display: inline-block;
            border-radius: 0; 
            padding: 5px 10px;
            border: $color-grey3 solid 1px;
            background: $color-white;
            color: $color-black;
            font-size: 14px;
            font-weight: 400;
            box-shadow: none;
        }
    }    
    .dataTables_length{    
        font-size: 14px;
        
        select{
            display: inline-block;
            border-radius: 0; 
            border: $color-grey3 solid 1px;
            background: $color-white;
            color: $color-black;
            font-size: 14px;
            font-weight: 400;
            box-shadow: none;
        
            padding: 4px 30px 4px 10px;
            appearance: none;
            text-indent: -2px;
            outline: 0;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='15' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23999999' fill-rule='evenodd' opacity='1' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 8px bottom 5px;
        }
    }
    .dataTables_info{    
        font-size: 14px;
    }
    .dataTables_paginate{    
        font-size: 14px;
    }
}