main{
    section{
        margin: $space*2 $space;
        .actions{
            float: right;
            font-size: $font-size;
            font-weight: 400;
        }
        clear: both;
    }
    article{
        margin-bottom: $space;
    }
    .container{
        clear: both;
    }
    a, a:visited, a:active{
        color: $color-red;
    }
    p{
        margin-bottom: $space;
    }
}
.dvgui-col-3{
    width: 25%;
    width: calc(25% - #{2*$space});
    margin: 0 $space;
    float: left;
}
.dvgui-col-4{
    width: 33.333%;
    width: calc(33.333% - #{2*$space});
    margin: 0 $space;
    float: left;
}
.dvgui-col-6{
    width: 50%;
    width: calc(50% - #{2*$space});
    margin: 0 $space;
    float: left;
}
.dvgui-col-8{
    width: 66.666%;
    width: calc(66.666% - #{2*$space});
    margin: 0 $space;
    float: left;
}
.dvgui-col-9{
    width: 75%;
    width: calc(75% - #{2*$space});
    margin: 0 $space;
    float: left;
}
.dvgui-col-12{
    width: 100%;
    width: calc(100% - #{2*$space});
    margin: 0 $space;
}

.dvgui-col-3{
    &.center{
        float: none;
        margin: auto;
    }
}

@media(max-width:750px){
    main{
        section{
            margin: $space;
        }
    }
}
@media(max-width:450px){
    main{
        section{
            margin: $space/2;
        }
        h2{
            font-size: 20px;
        }

    }
}