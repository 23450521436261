.green{
    color: #00cc00;
}
.yellow{
    color: #ffcc00;
}
.dvgui-image-item{
    margin-right: 1.333%;
    width: 24%;
    min-width: 150px;
    min-height: 150px;
    
    &:nth-child(2n+2){
        margin-right: 1.333%;
    }
    &:nth-child(4n+4){
        margin-right: 0;
    }

}
.nl-linked {
    display: flex;
    margin-bottom: 10px;
    cursor: move;
    &.grey{
        background: $color-grey1
    }
   
    .nl-date{
        flex: 90px 0 0; 
        font-size: 12px;
    }
    .nl-title{
        flex-grow: 2;
    }
    .nl-remove {
        flex: 30px 0 0;
    }
}
.dvgui-form-group{
    .dvgui-file-upload{
        font-size: 60px !important;
    }
}