body{
    &.public{
        header{
            .page-header-my, 
            .page-header-menu{
                display: none;
            }
        }
    }
}

header{
    background:  $header-background;
    height: $header-height;
    position: relative;
    
    .page-header-logo{
        position: absolute;
        top: 0;
        left: 0;
        height: $header-height;
        width: 20%;
        padding: $space;
        
        .logo{
            height: 100%;
        }
    }
    .page-header-menu{
        position: absolute;
        height: $header-height;
        width: 60%;
        top: 0;
        left: 20%;
    }
    .page-header-my{
        position: absolute;
        top: 0;
        right: 0;
        height: $header-height;
        width: 20%;
        
        .username{
            position: absolute;
            bottom: $space+5px;
            right: 50px + 2*$space + 30px + $space;
            text-align: right;
            
        }
        .img-circle{
            height: 50px;
            width:  50px;
            position: absolute;
            bottom: $space/2;
            right: $space;
        }
        .icon-logout{
            position: absolute;
            bottom: $space;
            right: 50px + 2*$space;
            font-size: 30px;
        }
    }
}