.dvgui-form-group {
	position: relative;    
  	margin: 0 0 10px 0;
  	padding-top: 17px;
    margin-right: $space;
    width: 100%;
    width: calc(100% - #{$space});
    z-index: 0;
    clear: both;
    
    .dvgui-text,
    .dvgui-select,   
    .dvgui-checkbox,
    .dvgui-file-upload,
    .dvgui-radio{
        display: block;
        width: 100%;
        padding: 5px 10px;
        border-radius: 0; 
		border: 0;
		background: $color-white;
        color: $color-black;
		font-size: $font-size;
        font-weight: 400;
		box-shadow: none;
        position: relative;
        border: solid 1px $color-grey2;
        
        ~ label {
			width: 100%;
			position: absolute;  
            top: 22px;       
			left: 10px;       
			bottom: 0;  
			pointer-events: none;     
            
            font-size: 16px;
            transition: 0.2s ease all;
            color: $color-grey3;
            margin: 0;
            
            &:after {
				content: '';  
			  	position: absolute;
			  	z-index: 5;
			  	bottom: 0;
			  	left: 50%;
			  	width: 0;   
			  	height: 3px;
			  	width: 0;  
			  	visibility: hidden;
			  	transition: 0.2s ease all;
			}
        }
        ~ .help-block {
            color: $color-black;
            display: none;
            &:not(:empty){
                opacity: 1;
            }
        }
		&:focus:not([readonly]) {
			~ .help-block {
				color: $color-black;    
                display: block;
				opacity: 1;
			}
		} 
        &[readonly],
        &.edited,
        &:focus:not([readonly]),  
        &.focus:not([readonly]) {			 
            ~ label {
				left: 0; 
				color: $color-black;  
                top: 0; 
                font-size: 12px;
            }
        }
                
        &:focus:not([readonly]),  
        &.focus:not([readonly]) {			 
            ~ label {
				&:after {
					visibility: visible;  
					left: 0;
					width: 100%; 
			  		background: $highlight-color1;
				} 
            }   
        }
        &.dvForm_missed{
            background: #ffffcc;
        }
    }

    .dvgui-select {     
		padding: 4px 30px 4px 10px;
        appearance: none;
        outline: 0;
        appearance: none;
        text-indent: -1px;
        outline: 0;
        ~ label {        
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='10' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23666666' fill-rule='evenodd' opacity='1' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 20px bottom 10px;
        }
   		&.edited:not([readonly]),
		&:focus:not([readonly]) {
			~ label {
				background-position: right 10px bottom 10px;
			}
		}  
    }
    .dvgui-checkbox{
        position:relative;
        border: 0;
        background: transparent;
        padding-top: 0;

        input[type=checkbox]   { 
            visibility: hidden;
            position: absolute;   
        }
        label {   
            cursor:pointer; 
            padding: 0 0 0 30px;
            margin: 0;
            width: 100%;
            text-align: left;
            display: block;
        }
        ~ label {
            left: 0; 
            color: $color-black;  
            top: 0; 
            font-size: 12px;            
        }   

        label > span { 
            display:block;    
            position:absolute; 
            left:0; 
            -webkit-transition-duration:0.3s; 
            -moz-transition-duration:0.3s; 
            transition-duration:0.3s;
        }

        label > span.inc   { 
          background: #fff;
          left:-20px;  
          top:-16px;
          height:60px;
          width:60px;
          opacity:0; 
          border-radius:50% !important; 
          -moz-border-radius:50% !important; 
          -webkit-border-radius:50% !important;
        }

        label > .box {
          top:4px;
          height:20px;   
          width:20px;
          z-index:5;
          -webkit-transition-delay:0.2s; -moz-transition-delay:0.2s; transition-delay:0.2s;
          border: 1px solid $color-black;  
          background: $color-white;
        }

        label > .check {
          top: 0;
          left: 6px;
          width: 10px;
          height: 20px;
          border:2px solid $color-black;  
          border-top: none;
          border-left: none;
          opacity:0; 
          z-index:5;
          -webkit-transform:rotate(180deg); 
          -moz-transform:rotate(180deg); 
          transform:rotate(180deg);
          -webkit-transition-delay:0.3s; 
          -moz-transition-delay:0.3s; transition-delay:0.3s;
        }

        label > span.inc {
          -webkit-animation   :growCircle 0.3s ease;
          -moz-animation      :growCircle 0.3s ease;
          animation           :growCircle 0.3s ease;   
        }

        input[type=checkbox]:checked ~ label > .box { 
          opacity:0;
          -webkit-transform   :scale(0) rotate(-180deg);
          -moz-transform        :scale(0) rotate(-180deg);
          transform                 :scale(0) rotate(-180deg);
        }

        input[type=checkbox]:checked ~ label > .check {
          opacity:1; 
          -webkit-transform   :scale(1) rotate(45deg);
          -moz-transform      :scale(1) rotate(45deg);
          transform           :scale(1) rotate(45deg);
        }

        // Disabled state
        input[type=checkbox]:disabled ~ label,
        input[type=checkbox][disabled] ~ label {
            cursor: not-allowed;   
            opacity: 0.5; 
        }

        input[type=checkbox]:disabled ~ label > .box,
        input[type=checkbox][disabled] ~ label > .box { 
            cursor: not-allowed;
            opacity: 0.5; 
        }

        input[type=checkbox]:disabled:checked ~ label > .check, 
        input[type=checkbox][disabled]:checked ~ label > .check {           
            cursor: not-allowed;
            opacity: 0.5; 
        } 
    }
 
    .dvgui-file-upload{
        display: block; 
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 200px !important;
        direction: ltr;
        cursor: pointer;
        
        ~ label {
            left: 0; 
            color: $color-black;  
            top: 0; 
            font-size: 12px;            
        }
        ~ .dvgui-file-result{
            display: inline-block;
            margin-left: $space;
        }
        ~ .dvgui-file-progress,
        ~ .dvgui-image-progress{
            width: 100%;
            background: $color-white;
            margin: 5px 0 5px 0;
            clear: both;
            overflow: hidden;
            display: none;
            
            div {
                color: #FFFFFF;
                text-align: right;
                padding: 2px 0;
                overflow: hidden;
                background: $color-grey2;
                span {
                    display: block;
                    margin: 4px 10px 0 0;
                }
            }
        }
        ~ .dvgui-file-notice,
        ~ .dvgui-image-notice{
            margin: 0 0 $space 0;
            border: 0;
            padding: $space/2;
            background: #fffdca;
            border-left: 5px solid #ffc71a;
            display: none;
        }

    }
    
    .dvgui-radio {
        position:relative;
        border: 0;
        background: transparent;
        padding-top: 0;

        &.inline{
            display: inline-block;
            margin-right: $space;
        }
        input[type=radio]   { 
            visibility: hidden;
            position: absolute;   
        }

        label {   
            cursor:pointer; 
            padding: 0 0 0 20px;
            margin: 0;
            width: 100%;
            text-align: left;
            display: block;
        }
        ~ label {
            left: 0; 
            color: $color-black;  
            top: 0; 
            font-size: 12px;            
        }   

        label > span { 
            display:block;    
            position:absolute; 
            left:0; 
            transition-duration:0.3s;
        }

        label > span.inc   { 
          background: #fff;
          left:-20px;  
          top:-20px;
          height:60px;
          width:60px;
          opacity:0; 
          border-radius:50% !important;
        }

        label > .box {
            top: 0px;
            height:20px;   
            width:20px;
            border-radius:50% !important;
            z-index:5;
            border: solid 1px $color-black;
            background: $color-white;
        }

        label > .check {
          top: 5px;
          left: 5px;   
          width: 10px;
          height: 10px;
          background: $color-black;  
          opacity:0; 
          z-index:6;  

          border-radius:50% !important;
          transform           :scale(0);
        }

        label > span.inc {
          -webkit-animation   :growCircle 0.3s ease;
          -moz-animation      :growCircle 0.3s ease;
          animation           :growCircle 0.3s ease;   
        }

        input[type=radio]:checked ~ label > .check {
          opacity:1; 
          -webkit-transform   :scale(1);
          -moz-transform      :scale(1);
          transform           :scale(1);
        }

        // Disabled state
        input[type=radio]:disabled ~ label,
        input[type=radio][disabled] ~ label {
            cursor: not-allowed;   
            opacity: 0.5; 
        }

        input[type=radio]:disabled ~ label > .box,
        input[type=radio][disabled] ~ label > .box { 
            cursor: not-allowed;
            opacity: 0.5; 
        }

        input[type=radio]:disabled:checked ~ label > .check, 
        input[type=radio][disabled]:checked ~ label > .check {           
            cursor: not-allowed;
            opacity: 0.5; 
        }
    }
    .help-block { 
		position: absolute;
	  	margin: 2px 0 0 0;   
	  	opacity: 0;   
	  	font-size: 13px;
	}
     
	.help-block { 
		position: absolute;
	  	margin: 2px 0 0 0;   
	  	opacity: 0;   
	  	font-size: 13px;
	}
    .dvForm_missed{
        background: $message-warning-background;
    }
    &.descriptors-container{
        overflow: hidden;
        .dvgui-checkbox{
            width: 150px;
            float: left;
            
            b{
                background: $color-white;
                padding: 5px;
                margin-right: 5px;
                display: inline-block;
                border: solid 1px $color-grey2;
                line-height: 12px;
                font-weight: normal;
                color: $color-black;
                font-size: 12px;
            }
        }
    }
    &.w20{
        width: 20%;
        display: inline-block;
    }
    &.w30{
        width: 30%;
        display: inline-block;
        width: calc(30% - #{$space});
        float: left;
        clear: none;
    }
    &.w50{
        width: 50%;
        width: calc(50% - #{$space});
        display: inline-block;
        float: left;
        clear: none;
    }
    &.w70{
        width: 70%;
        width: calc(70% - #{$space});
        display: inline-block;
        float: left;
        clear: none;
    }
    &.w160{
        width: 160px;
        display: inline-block;

    }

    &.inline{
        .dvgui-radio{
            display: inline-block;
            width: 150px;
        }
    }
    &.hide{
        display: none;
    }
}

.dvgui-file-upload-result{
    margin-top: 20px;
}
.dvgui-image-item{
    border: solid 1px $color-black;
    display: inline-block;
    margin-right: 2%;
    margin-bottom: $space;
    width: 48%;
    min-height: 150px;
    position: relative;
    
    &:nth-child(2n+2){
        margin-right: 0;
    }
    &:hover{
        .image-container {
            background-size: contain;
        }
    }
    .delete-image, .paint-image, .move-image, .rotate-image, .download-image{
        position: absolute;
        background: $color-grey3;
        width: 40px;
        height: 40px;
        z-index: 10;
        top: $space/2;
        right: $space/2;
        cursor: pointer;
        color: $color-white;
        
        i {
            font-size: 30px;
            padding: 5px;
            display: inline-block;
        }
    }
    .move-image{
        right: auto;
        bottom: auto;
        left: $space/2;
        top: $space/2;
    }
    .rotate-image{
        background: $color-grey1;
        left: auto;
        top: auto;
        right: $space/2;
        bottom: 82px;
    }
    .paint-image{
        background: $color-grey1;
        left: auto;
        top: auto;
        right: $space/2;
        bottom: $space/2+27px;
    }
    .download-image{
        left: $space/2;
        bottom: $space/2+27px;
        right: auto;
        top: auto;
        background: none;
    }
    .image-container {
        width: 100%;
        height: 150px;
        border: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-black;
    }
    
    input{
        border: 0;
        border-top: solid 1px $color-black;
        width: 100%;
        font-size: $font-size;
        line-height: 12px;
        padding: 5px;
        font-family: $font;
    }
    .formcheck-icon{
        height: 1rem;
        width: 1rem;
        color: $highlight-color1;
        position: absolute;
        bottom: .3rem;
        right: .3rem;
    }

}
.dvgui-document-item{
    border: solid 1px $color-black;
    display: inline-block;
    margin-right: 2%;
    margin-bottom: $space;
    width: 48%;
    min-height: 30px;
    position: relative;
    background: $color-grey2;
    
    &:nth-child(2n+2){
        margin-right: 0;
    }
    .delete-document, .move-document{
        position: absolute;
        background: $color-grey3;
        width: 40px;
        height: 40px;
        z-index: 10;
        top: $space/2;
        right: $space/2;
        cursor: pointer;
        color: $color-white;
        
        i {
            font-size: 30px;
            padding: 5px;
            display: inline-block;
        }
    }
    .move-document{
        right: auto;
        bottom: auto;
        left: $space/2;
        top: $space/2;
    }
    
    .dvgui-document-container {
        width: 100%;
        min-height: 30px;
        border: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-grey2;
        padding: $space $space $space 60px;
    }
    
    input{
        border: 0;
        border-top: solid 1px $color-black;
        width: 100%;
        font-size: $font-size;
        line-height: 12px;
        padding: 5px;
        font-family: $font;
    }
    .formcheck-icon{
        height: 1rem;
        width: 1rem;
        fill: $highlight-color1;
        position: absolute;
        bottom: .3rem;
        right: .3rem;
    }
}

.dvgui-form-break {
    width: 100%;
    height: 1px;
}    

@-webkit-keyframes growCircle {
  0%, 100%   { -webkit-transform:scale(0); opacity: 0.5}
  70%        { background: $highlight-color1; -webkit-transform:scale(1.25); }
}
@-moz-keyframes growCircle {
  0%, 100%   { -moz-transform:scale(0); opacity: 0.5}
  70%        { background: $color-red; -moz-transform:scale(1.25); }
}
@keyframes growCircle {
  0%, 100%   { transform:scale(0); opacity: 0.5}
  70%        { background: $color-red; transform:scale(1.25); }
}

.form-actions,
.notice-container{
    overflow: hidden;
    padding: $space 0 $space 0;
    display: block;
    margin-top: -8px;
    margin-right: $space;
    width: 100%;
    width: calc(100% - #{$space});
    
    .btn{
        float: right;
        &.nofr{
            float: none;
        }
    }
}
.actions{
    .dvgui-form-group {
        display:  inline-block;
        width: 150px;
        margin-top: -18px;
            
        .dvgui-text{
            border: $color-grey2 solid 1px
        }
    }
}
.toggle-condition{
    display: none;
    overflow: hidden;
    clear: both;
    
    .show{
        display: block;
    }
}