body {
    background: $background-color;
    font-family: $font;
    font-size: $font-size;
    color: $font-color;
}  
b,strong {
    font-weight: 700;
}
.col-centered{
    margin: auto;
}
.container-fluid {
    padding-left: 60px;
    padding-right: 60px;
}
a, a:link, a:visited, a:active{
    color: $link-color;
}
a:hover{
    color: lighten($link-color, 30);
}
p{
    margin-bottom: 0;
}
.mr {
    margin-right: $space;
}
.mr1 {
    margin-right: $space/2;
}
.ml {
    margin-left: $space;
}
.mb {
    margin-bottom: $space;
}
.mt {
    margin-top: $space;
}
.oh{
    overflow: hidden;
}
.fr{
    float: right;
}
.fl{
    float: left;
}